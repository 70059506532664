<template>
  <div class="container-fluid m-0 p-0">
    <div class="bg"></div>
		<h1 class="nav-title">PartnerShip {{this.env}}</h1>
		<div id="navbar" class="nav-bar">
      <div class="nav-line">
        <span class="icon-menu-hamburger" @click="showMenu = !showMenu"></span>
        <transition name="expand-down">
          <div class="nav-selection" v-show="showMenu" id="navMenu">
            <label class="label disabled" style="text-align:center; display:flex; flex-direction:column;">
              <span class="fas fa-user-circle"/>
              <span v-if="user">{{user.name}}</span>
            </label>
			<label v-for="t in tabs" :key="t.val" class="label" @click="goToPage(t.val)">{{$t(t.text)}}</label>
			
			<label class="label disabled">
				<img @click="changeLang('es')" :class="{'selected': isSelectedLang('es')}" src="https://upload.wikimedia.org/wikipedia/commons/7/7c/Spain_flag_icon.svg">
				<img @click="changeLang('en')" class="mx-2" :class="{'selected': isSelectedLang('en')}" src="https://freesvg.org/img/united_kingdom.png" style="height:27px">
				<img @click="changeLang('fr')" :class="{'selected': isSelectedLang('fr')}" src="https://image.pngaaa.com/324/1388324-middle.png">
				<img @click="changeLang('pt')" class="mx-2" :class="{'selected': isSelectedLang('pt')}" src="https://cdn.countryflags.com/thumbs/portugal/flag-3d-round-250.png" style="height:28px">
				<img @click="changeLang('it')" :class="{'selected': isSelectedLang('it')}" src="https://aux2.iconspalace.com/uploads/401536091.png">
			</label>
			
          </div>
        </transition>
        <div class="nav-logo">
          <img class="nav-logo-img" src="@/assets/images/logo-leroymerlin-mini.svg"/>
        </div>
		<h5>{{$t(getPageText())}}</h5>
		<div class="provider-menu" v-if="showProviderSelect()">
			<h6 class="form-label mt-2">{{$t('edition.provider')}}:</h6>
			<model-list-select :list="getProviders()" option-value="leroy_id" class="form-control" :custom-text="codeAndName" v-model="providerImpersonate" :placeholder="$t('all')"/>
		</div>
      </div>
    </div>
	<div class="container-fluid p-3 m-0" style="max-height:calc(100vh - 200px); overflow-y:auto">
		<router-view v-if="user"/>
	</div>
   
  </div>
</template>

<script>
import {getMe} from '@/services/auth'
import {getProviderList} from '@/services/providers'
import {subDays} from 'date-fns'
import {ModelListSelect} from 'vue-search-select'
import {getEnvironment} from '@/services/utils'

export default {
	components: { ModelListSelect },
	name: 'Home',
  data: () =>{
    return {
      showMenu: false,
	  tabs:[
		  {val:'prepare', text: 'orders-2-prepare'},
		  {val:'history', text: 'order-history'},
		  {val:'providers', text: 'provider-management'},
		  {val:'logout', text: 'logout'},
	  ],
	  providers:[],
	  filters:{	
		creationDate: [ subDays(new Date(),7), new Date()],
		deliveryPromiseDateToCarrier: null,	
		status: null, 
		carrierName: null, 
		provider: 'all'
	  },
	  env: getEnvironment()
    }
  },
  async created(){ 
	  this.user = await getMe()	  
  },
  mounted(){
	  document.body.addEventListener('click', el => {
			if(this.showMenu && !['icon-menu-hamburger'].includes(el.srcElement.classList.value))
				this.showMenu = false
	  })
	  this.loadProviders()
  },
  methods:{
	async loadProviders(){
            try{
                let {data} = await getProviderList()
                this.providers = data
            }catch(err){ 
				console.log(err)
			}
    },
	getProviders(){
            return [...[{leroy_id:'all', name: this.$t('all')}, ...this.providers]]
        },

	changeLang(lang){ this.$t.locale=lang },
	isSelectedLang(lang){ return this.$t.locale==lang},
	goToPage(p){ if(this.$route.name!==p)this.$router.push({name: p})},
	getPageText(){  return this.tabs.find(t=> this.$route.name==t.val)?.text},
	showProviderSelect(){ return this.$route.name=='providers'?false:true}
    
  }
}
</script>
<style lang="scss" scoped>
.bg{
  position: fixed;
  top:0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: -10;
  background: url('~@/assets/images/almacen.jpg');
  background-size: cover;
  background-position: center;
  opacity: .1;
}
.nav-title {
	color: $white;
	line-height: 40px;
	font-size: 1rem;
	background:$dark-gray;
	text-align: center;
	font-family: 'LM-regular', 'Helvetica', 'Arial';
	margin-bottom: 0;
}
.nav-bar {
	width: 100%;
	background: $white;
	border-bottom: 1px solid $borders;
	padding: 0;
	@media(max-width: 1400px) {
		padding: 0 1rem;
	} 
}
.nav-logo-img {
	width: auto;
	height: 40px;
	text-align: center;
	margin-left: 2rem;
	@media(max-width: 500px) {
		height: 32px;
		width: auto;
		margin-left: .8rem;
	} 
}
.nav-line {
	position: relative;
	display: flex;
	width: 100%;
	background: $white;
	max-width: 1366px;
	margin: 0 auto;
	align-items: center;
	height: 100%;
	padding-top: .8rem;
	padding-bottom: .8rem;
	.nav-btn {
		color: $light-green;
		margin-top: 10px;
		font-size: 20px;
		margin-right: 10px;
		cursor: pointer;
		&:hover {
			color: white;
		}
	}
	h5{
		color:$green;
		line-height: 2rem;
		padding-top: 5px;
		padding-left: 10px;
	}
}
.select {
	cursor: pointer;
	font-size: 0.8rem;
	margin-right: 6px;
	background: $white;
	color: $dark-gray;
	outline: 0;
	border: 0;
	border-bottom: 1px solid $medium-grey;
	background: #f7f7f7;
    height: 48px;
	padding: 1rem;
	font-family: 'LM-light', 'Helvetica', 'Arial';
	outline: none;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	transition: all 0.25s ease;
	padding-right: 2.4rem;
	&::-ms-expand {
		display: none;
	}
	option {
		background: $white;
		max-height: 100px;
		color: $dark-gray;
	}
}
.content-select {
	margin-left: auto;
	position: relative;
	.icon-arrowDown {
		position: absolute;
		color: $light-green;
		right: 1.2rem;
		top: 1.4rem;
		font-size: .6rem;
	}
}

.icon-menu-hamburger {
	color: $light-green;
	cursor: pointer;
	font-size: 1.6rem;
}

.nav-selection {
	position: absolute;
	z-index: 100;
	top: 100%;
	left: 0;
	display: flex;
	flex-direction: column;
	background: $white;
	transform-origin: top;
	border-radius: 4px;
	border: solid 1px $light-border;
	border-top: 0;
	box-shadow: 1px 2px 2px 0 $shadow;

	.label {
		margin-bottom: 0;
		padding: 4px 8px;
		text-align: right;
		color: $dark-gray;
		border-top: 1px solid $light-border;
		
		font-family: 'LM-regular', 'Helvetica', 'Arial';
		padding: .8rem 1.6rem;
		text-align: left;
		&:not(.disabled){
			cursor: pointer;
			&:hover { background: $background; }
		}
		span{
			color:$light-green;
		}
		img{
			height: 30px;
			width:auto;
			opacity: .4;
			cursor: pointer;
			&.selected{ opacity: 1;}
		}
	}

	.--selected {
		background: $background;
	}
}

.expand-down-enter-active, 
.expand-down-leave-active {
	overflow: visible;
	max-height: 575px;
	transition: all .3s linear;
}

.expand-down-enter,
.expand-down-leave-to {
	overflow: hidden;
	max-height: 0;
	transition: all .3s linear;
}

@media (max-width: 500px) {
	.nav-logo {
		top: 0px;
		left: 0px;
		border-radius: 0;
	}
	.nav-line {
		margin-top: 0;
		padding-top: .5rem;
		padding-bottom: .5rem;
	}
	.nav-title {
		font-size: 13px;
	}
	.nav-btn {
		font-size: 30px;
		margin-top: 4px;
		margin-right: 4px;
	}

	.nav-selection {
		width: 100%;
		padding-bottom: 4.5rem;
		.label {
			text-align: center;
		}
	}
	.content-select {
		display: none;
		width: 60%;
		margin-top: .5rem;
		margin-bottom: .5rem;
		.icon-arrowDown {
			right: .5rem;
			top: 1.2rem;
		}
	} 
	.select {
		width: 100%;
		padding: .5rem 2rem .5rem .5rem;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: .8rem;
	}
	.show-select {
		display: block;
		position: absolute;
		top: 450px;
		z-index: 999;
		left: 0;
		right: 0;
		width: 89%;
		margin: .5rem auto 0 auto;
		&.reader{ top:200px; }
	}
}
h4{
  text-align: center;
  padding: .4rem;
}
.provider-menu {
	margin-left: auto;
    align-items: flex-end;
	width: 100%;
	background: $white;
	min-width: 300px;
	max-width: 400px;
	color: $green;
	height: 100%;
	font-size: .5rem;
	border: 0px solid black;
	transform: scale(0.8);
	z-index: 1;
	
}

</style>
