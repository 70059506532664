<template>
    <div class="row justify-content-center">
        <div class="col-auto">
            <a :href="getLoginUrl()" class="button"> Volver a entrar</a>
        </div>
    </div>    
</template>
<script>
import { logout } from '@/services/auth'
export default {
    data: () =>{
        return {
        }
    },
    mounted(){
        this.logout()
    },
    methods:{
        getLoginUrl(){
            return `${process.env.VUE_APP_API_URL}/oauth2/authorization/pingid`
        },
        async logout(){
            try{
                let result = await logout()
            }catch(err){ 
                console.log(err)
            }
        },
    }
}
</script>