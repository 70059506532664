import { api } from "./http";

const path = '/api/providers'

export const getProviderList = async () => api.get(path)

export const getProvider = async lmId => api.get(`${path}/${lmId}`)

export const updateProvider = async provider => api.put(`${path}/${provider.id}`, provider)

export const validateProviderAddress = async address => api.post(`${path}/addresses`, address)
