import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import ActiveOrders from '../views/Orders/ActiveOrders.vue'
import OrderHistory from '../views/Orders/OrdersHistory.vue'
import Providers from '../views/Providers.vue'
import Logout from '../views/Expired.vue'
Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', redirect: '/home/orders/prepare'},
    { path: '/home', component: Home, children: [
      { path: 'orders/prepare', name:'prepare', component: ActiveOrders},
      { path: 'orders/history', name:'history', component: OrderHistory },
      { path: 'providers', name: 'providers', component: Providers },
      { path: 'logout', name: 'logout', component: Logout },
      { path: '/', redirect: '/home/orders/prepare' }
    ]},
    { path: '**', redirect: '/home/orders/prepare'}
    
  ]
})

export default router
