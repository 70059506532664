import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'vue2-datepicker/index.css';
import 'vue-step-progress/dist/main.css';
import 'vue-search-select/dist/VueSearchSelect.css'
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);
import pack from '../package.json'

import VueTranslator from 'vue-translator'
import FR from './assets/lang/fr.json'
import ES from './assets/lang/es.json'
import EN from './assets/lang/en.json'
import PT from './assets/lang/pt.json'
import IT from './assets/lang/it.json'

import { ROL_ADMIN, ROL_PROVIDER } from '@/services/utils'

Vue.use(VueTranslator, {
  locale: navigator.language?.substring(0,2) ?? 'es', 
  translations: {  es : ES, fr: FR,  en: EN, pt: PT, it: IT},
  defaultLocale: 'es', 
  filter: false,
  merge: null 
})

let globalData = new Vue({ data: { $user: null, $providerImpersonate: null}})
Vue.mixin({
  computed: {
    user: {
      get: function() { return globalData.$data.$user; },
      set: function(u) { globalData.$data.$user = u; }
    },
    providerImpersonate: {
      get: function() { return globalData.$data.$providerImpersonate; },
      set: function(p) { globalData.$data.$providerImpersonate = p; }
    }
  },
  methods:{
    getVersion: () =>{ return pack.version},
    showToast: function(msg,icon){
      Vue.swal.mixin({ toast:true, timer: 3000, position:'top-end', showConfirmButton: false })
        .fire({ title: msg, icon: icon })
    },
    codeAndName(s){ return `${s.leroy_id!='all' ?s.leroy_id : ''}${s.leroy_id!='all' && s.name? ' | ':''}${s.name ?? ''}`},
    codeAndNameCarriers(s){ return `${s.name ?? ''}`},
    isAdmin: () => globalData.$data.$user?.role==ROL_ADMIN ? true : false,
    isProvider: () => globalData.$data.$user?.role==ROL_PROVIDER ? true : false
    
  }
  
})

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
