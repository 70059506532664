<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-6">
        <step-progress :steps="[$t('edition.step1'),$t('edition.step2'),$t('edition.step3')]" :current-step="currentStep" 
          :active-thickness="2" :passive-thickness="2" :line-thickness="2"
          :active-color="'#78BE20'" style="margin-bottom:0!important"/>
      </div>
    </div>
    <div class="row justify-content-center my-3">
      <div class="col-auto loader" v-if="loading">
          <section class="spinner-wrapper" id="spinnerWrapper">
              <div class="spinner" data-id="spinner">
                  <div class="spinner-cell">
                      <div class="pl pl-leapfrog"></div>
                  </div>
              </div>
          </section>
          <h4>{{loadingMsg}}</h4>
      </div>
      <div class="col-auto" v-else-if="currentStep==0">
        <div class="row info-box mb-3">
          <div class="col-12"><h5>{{$t('edition.estimate')}}</h5></div>
          <div class="col-auto">{{$t('edition.weight')}}: <span>{{order.weight.toFixed(2)}} kg</span></div>
          <div class="col-auto">{{$t('edition.volume')}}: <span>{{order.volume.toFixed(2)}} cm&sup3;</span></div>
        </div>
        <div class="row justify-content-between form-group info-box">
            <div class="col-auto"><label style="line-height:35px">{{$t('edition.bulk_info')}} </label></div>
            <div class="col-auto" style="display:flex; flex-direction:row;line-height:35px">
              <button class="bulk-btn" @click="bulkWeights.pop()"><i class="fas fa-minus"></i></button>
              <label class="bulk-label mx-2">{{bulkWeights.length}}</label>
              <button class="bulk-btn" @click="bulkWeights.push(null)"><i class="fas fa-plus"></i></button>
            </div>
        </div>
        <div class="row justify-content-between form-group info-box" v-for="(w,i) in bulkWeights" :key="`weight${i}`">
          <div class="col-auto">{{$t('edition.bulk_weight')}} {{i+1}}:</div>
          <div class="col-3"><input type="number" step="0.001" class="form-control" v-model="bulkWeights[i]"></div>
        </div>
        <div class="col-12 mt-4 info-box" style="font-size:.7rem; border-bottom:0px; color: red">
            {{$t('edition.bulk_warn')}}
        </div>
      </div>
      <resume ref="resume" v-else-if="currentStep==1" :order="order" :provider="provider" :bulkWeights="bulkWeights" :selectedAddress="selectedAddress" @selectAdr="selectedAddress=$event"/>
      <div v-else>
        <h4 style="text-align:center"> {{$t('edition.tickets_ok')}} </h4>
      </div>
    </div>
    <div class="row prepare-buttons justify-content-end">
      <div class="col-auto">
        <button class="button negative" @click="goBack()">{{$t('exit')}}</button>
        <button class="button negative" v-if="![0,3].includes(currentStep)" @click="currentStep--">{{$t('back')}}</button>
        <button class="button" @click="step()" :disabled="!canStep()">{{$t('next')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
import StepProgress from 'vue-step-progress';
import { modifyOrder} from '@/services/orders'
import Resume from './preparation/Resume.vue';
import {getProvider, validateProviderAddress} from '@/services/providers'
import {printOnWindow} from '@/services/utils'
export default {
  name: 'order-prepare',
  components:{'step-progress': StepProgress, Resume},
  props: ['order'],
  data: () =>{
    return {
      loading:false,
      loadingMsg:'Obteniendo transportista',
      currentStep:0,
      carrier: null,
      bulkNumber:0,
      bulkWeights: [],
      selectedAddress: null,
      provider: null
    }
  },
  async mounted(){ 
    await this.loadProvider()
    this.bulkWeights = this.getBulkWeights()
    this.selectedAddress =  this.order?.transport?.provider ?? this.provider.addresses.find(a=> a.is_default)

  },
  methods:{
    async loadProvider(){
      try{
        this.provider = (await getProvider(this.order.supplierCode)).data
      }catch(err){
        console.log(err)
      }
    },
    async loadCarrier(){
      try{
        this.carrier = (await getProvider(this.order.supplierCode)).data
      }catch(err){
        console.log(err)
      }
    },
    getBulkWeights(){
      if(this.order.transport?.packages){
        return this.order.transport.packages.map(p=> p.weight)
      } else return this.order.transport?.bulkWeights ?? []
    },
    
    getAddress(a){
      let res = `${a.address1} ${a.address2 || ''}, ${a.district || ''} ${a.city ||''}`
      res+= `${a.district || a.city ? ', ':''}${a.province}, ${a.country_name}`
      return res
    },
    
    getProdQuantity(){
      return this.order.orderLines.reduce((res,l)=> res+=Number(l.quantity),0)
    },
    getTotalWeight(){ return (this.bulkWeights.reduce((res,w)=> res+=w?Number(w):0 , 0) || this.order.weight).toFixed(2) },
    canStep(){
      return ((this.currentStep==0 && this.bulkWeights.length>0 && 
      [this.bulkWeights.length,0].includes(this.bulkWeights.filter(b=>b).length)) || this.currentStep==1)
    },
    async step(){
      if(this.canStep()){
        if(this.currentStep==0 && this.bulkWeights[0] && this.isOverWeight()){
          let {value} = await await this.$swal({ title: this.$t('warn'),
                icon: 'warning',
                type: 'warning',
                text: this.$t('preparation.weight_exceed'),
                cancelButtonText: this.$t('cancel'),
                confirmButtonText: this.$t('accept'),
                showCancelButton: true, 
                reverseButtons:true})
          if(value) this.currentStep++
        } else this.currentStep++ 
      }
      if(this.currentStep==2) {
        try{         
          await validateProviderAddress(this.selectedAddress)       
          this.printLabel()
        }catch(err){
          console.log(err)
          await this.$swal(this.$t('error'), this.$t('validation_address.invalid'), 'error')  
          this.currentStep--       
        }
      }
    },
    isOverWeight(){
      let totWeight = this.bulkWeights.reduce((res,w)=> res+=Number(w),0)
      return this.order.weight<(totWeight*0.9) || this.order.weight>(totWeight*1.1)
    },
    async goBack(){
      if(['init','in_progress'].includes(this.order.status) && this.currentStep==1) await this.setOrderInProgress()
      else this.$emit('exit')
    },
    async setOrderInProgress(){
      this.loadingMsg='edition.saving_weight'
      this.loading = true
      
      try{
        if(!this.order.transport) this.order.transport= {}
        this.order.transport.bulkWeights = this.bulkWeights.length>0 && !this.bulkWeights[0] ?
            this.bulkWeights.map(w=> Number((this.order.weight/this.bulkWeights.length).toFixed(2))) : this.bulkWeights
        this.order.transport.provider = this.selectedAddress
        this.order.transport.provider.name = this.provider.name
        this.order.status='in_progress'
        await modifyOrder(this.order)
        this.$emit('exit')
      }catch(err){
        console.log(err)
        this.$swal(this.$t('error'), this.$t('edition.error_saving'), 'error')
      }
    },
    
    async printLabel(){
      this.loadingMsg=this.$t('edition.labeling')
      this.loading = true

      try{
        if(!this.order.transport) this.order.transport= {}
        this.order.transport.bulkWeights = this.bulkWeights.length>0 && !this.bulkWeights[0] ?
            this.bulkWeights.map(w=> Number((this.order.weight/this.bulkWeights.length).toFixed(2))) : this.bulkWeights
        this.order.transport.provider = this.selectedAddress
        this.order.transport.provider.name = this.provider.name
        this.order.status = 'labeled'
        let {data} = await modifyOrder(this.order)
        printOnWindow(data.pdf, this.order)
        setTimeout(()=> { this.$emit('exit')}, 2000)
      }catch(err){
         console.log(err)
        this.$swal(this.$t('error'), this.$t('edition.error_labeling'), 'error')
      }
      this.loading = false
    }
    
  }

}
</script>

<style scoped lang="scss">

.form-control{
  max-width: 180px;
}

.info-box {
    background: rgba(255,255,255,.8);
    color: $dark-gray;
    box-shadow: 1px 2px 2px 0 $shadow;
    border-radius: 2px;
    padding: 4px;
    h5{ color:$green;}
    span{ font-weight: 600;}
    .bulk-btn{
      width:30px;
      height: 30px;
      font-size: 18px;
      line-height: 12px;
      text-align: center;
      padding: 0px;
      border:none;
      border-radius: 2px;
      background: $green;
      color:white;
    }
    .bulk-label{
      color:$green;
      font-size: 1.4rem;
    }
}

.prepare-buttons{
  position: absolute;
  bottom: 2rem;
  right:2rem;

  .button{
    width: 140px;
    padding: .6rem;
    margin-right: .5rem;
    &:disabled{
      filter: grayscale(100%) !important;
      cursor: not-allowed;
    }
  }
}

</style>
