import { api } from "./http"

export const getMe = async () => {

   try{
      let {data} = await api.get('/me')
      return data
   }catch(err){

   }
}


export const logout = async () => {

   try{
      let {data} = await api.post('/logout')
      
   }catch(err){
      console.log(err)
   }
}


