<template>
    <div class="container-fluid">
        <div v-if="!selectedProvider"  class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-10 mb-2">
                <label class="form-label">{{$t('address.search')}}:</label>
                <input v-model="search" class="form-control iniput">
            </div>
            <div class="col-10">
                <div class="row" style="background: rgb(197, 253, 197)">
                    <div class="col-4 prov-head my-1 p-2" v-for="(h,i) in headers" :key="`header${i}`" @click="sortTable(h.val)">
                        {{$t(h.text)}} <i v-if="h.sortable && sort.value==h.val" :class="`fas fa-sort-amount-${sort.order=='ASC'?'up':'down'}`"></i>
                    </div>
                </div>
            </div>
            <div class="col-10 prov-box my-1 p-2" v-for="(p,i) in getFilteredProviders()" :key="`prov${i}`" @click="selectProvider($event,p)">
                <div class="row m-0 p-0">
                    <div class="col-4" v-for="(h,j) in headers" :key="`cell${i}.${j}`">
                        <template v-if="h.val=='is_enable'">
                            <i v-if="p[h.val]" class="fab fa-creative-commons-by" @click="enable(p)"></i>
                            <i v-else class="fas fa-ban" @click="enable(p)"></i>
                        </template>
                        <template v-else>{{p[h.val]}}</template>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="row">
            <h3>{{selectedProvider.name}}</h3>
            <address-managment :provider="selectedProvider" @loadProvider="selectProvider($event,selectedProvider)" @back="selectedProvider=null"/>
        </div>
    </div>
</template>
<script>
import { getProviderList, getProvider, updateProvider, validateProviderAddress} from'@/services/providers'
import Address from '@/components/Address'
export default {
    name:'providers',
    components:{'address-managment':Address},
    data: () =>{
        return {
            providers: [],
            headers:[
                {val: 'leroy_id', text: 'edition.provider', sortable: true},
                {val: 'name', text: 'edition.name', sortable: true}                
            ],
            sort:{value: null, order: 'ASC'},
            search:'',
            selectedProvider: null
        }
    },
    created(){ if(this.isAdmin()) this.headers.push({val: 'is_enable', text: 'order.status', sortable: true})},
    mounted(){ this.getList() },
    methods:{
        async getList(){
            try{
                let {data} = await getProviderList()
                this.providers = data
                this.selectedProvider = null
            }catch(err){
                console.log(err)
                this.$swal(this.$t('error'),this.$t('providers.not_found') ,'error')
            }
        },
        getFilteredProviders(){
            return this.providers.filter(p => !this.search || p.leroy_id.includes(this.search) || p.name.toUpperCase().includes(this.search.toUpperCase()))
        },
        sortTable(key){
            this.sort.order = this.sort.value==key && this.sort.order== 'ASC'? 'DESC': 'ASC'
            this.sort.value = key

            this.providers = this.providers.sort((a,b)=>{
                if(String(a[key]).toLowerCase()<String(b[key]).toLowerCase()) return this.sort.order== 'ASC'? 1: -1
                else if(String(a[key]).toLowerCase()>String(b[key]).toLowerCase()) return this.sort.order== 'ASC'? -1 : 1
                else return 0
            })
        },
        async selectProvider(event,{leroy_id}) {
            if(!event?.srcElement?.classList?.value?.includes('fa-')){
                try{
                    let {data} = await getProvider(leroy_id)
                    this.selectedProvider = data
                }catch(err){
                    console.log(err)
                }
            }
            
        },
        async enable({leroy_id, is_enable}){
            try{
                let {data} = await getProvider(leroy_id)
                if (!is_enable)                
                    await validateProviderAddress(data.addresses.find(a=> a.is_default))
                try{                                        
                    data.is_enable= is_enable? false : true
                    await updateProvider(data)
                    this.getList()
                    this.showToast(this.$t('edition.change_saved'), 'success')
                }catch(err){
                    console.log(err)
                    this.showToast(this.$t('edition.error_saving'),'error')
                }            
            }catch(err){
                console.log(err)
                this.$swal(this.$t('error'), this.$t('validation_address.invalid'), 'error')              
            }
        }
    }
}
</script>
<style lang="scss" scoped>

.form-control{
    height: 34px;
    line-height: 20px;
    color:#888;
}
.form-label{
    color:$green;
    margin-bottom:0;
}


.prov-box{
    background: white;
    color: $dark-gray;
    box-shadow: 1px 2px 2px 0 $shadow;
    &:hover{
        box-shadow: 0px 0px 2px 2px rgba(0, 153, 255, .4);
        cursor: pointer;
        font-weight: 550;
    }
}

.prov-head{
    background: rgb(197, 253, 197);
    font-family: 'LM-Semibold';
        &:hover{
            filter:brightness(95%);
            cursor: pointer;
        }
}

.fa-creative-commons-by{
    color:$blue;
    font-size: 1.2rem;
}
.fa-ban{
    color:$red;
    font-size: 1.1rem;
}
</style>