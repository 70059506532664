<template>
    <div class="col-auto resume-box p-3">
        <div class="row">
          <h5>{{$t('edition.provider')}}</h5>
          <div class="col-4">{{$t('edition.name')}}:</div>
          <div class="col-8">{{provider.name}}</div>
          <div class="col-4">{{$t('edition.pickup_address')}}:</div>
          <div class="col-8">{{getAddress(selectedAddress)}} <i class="fas fa-map-marker-alt" :title="$t('edition.change_adr')" @click="showModal=true"></i></div>
          <div class="col-4">{{$t('edition.zipcode')}}:</div>
          <div class="col-8">{{selectedAddress.zipcode}}</div>
          <h5 class="mt-4">{{$t('edition.carrier')}}</h5>
          <div class="col-4">{{$t('edition.name')}}</div>
          <div class="col-8">{{getCarrier()?getCarrier().name: $t('edition.toBeDefined')}} 
            <i v-if="!getCarrier()" class="far fa-question-circle" :title="$t('edition.no_carrier')"></i>
          </div>
          <h5 class="mt-4">{{$t('edition.order')}}</h5>
          <div class="col-4">{{$t('edition.tot_bulks')}}:</div>
          <div class="col-8">{{bulkWeights.length}}</div>
          <div class="col-4">{{$t('edition.tot_prod')}}:</div>
          <div class="col-8">{{getProdQuantity()}}</div>
          <div class="col-4">{{$t('edition.tot_weight')}}:</div>
          <div class="col-8">{{getTotalWeight()}} kg</div>
          <div class="col-4">{{$t('edition.refProvider')}}:</div>
          <div class="col-8"><textarea class="form-text-area" v-model='order.refProvider'></textarea></div>
          <div class="col-12 mt-4" style="font-size:.7rem; border-bottom:0px">
            {{$t('edition.resume_info')}}
          </div>
        </div>
        <adr-modal :show="showModal" :title="'Seleccione una dirección de recogida'" :loading="false" @close="showModal=false">
            <template slot="body">
                <div class="col-12" v-for="(ad,i) in provider.addresses" :key="`adr${i}`">
                    <label class="adr-label" :class="{'selected': getAddress(ad)==getAddress(selectedAddress)}" @click="selectAdr(ad)">{{getAddress(ad)}}</label>
                </div>
            </template>
        </adr-modal>
      </div>
</template>
<script>
import Modal from '@/components/Modal'
export default {
    name:'resume',
    components:{'adr-modal': Modal},
    props:['order', 'bulkWeights', 'selectedAddress', 'provider'],
    data:()=>{
        return { showModal: false}
    },
   
    methods:{
        getAddress(a){
            if(a){
                let res = `${a.address1} ${a.address2 || ''}, ${a.district || ''} ${a.city ||''}`
                res+= `${a.district || a.city ? ', ':''}${a.province}, ${a.country_name}`
                return res
            }else return ''
            
        },
        getCarrier(){ return this.order.transport?.carrier },
        getProdQuantity(){
            return this.order.orderLines.reduce((res,l)=> res+=Number(l.quantity),0)
        },
        getTotalWeight(){ return (this.bulkWeights.reduce((res,w)=> res+=w?Number(w):0 , 0) || this.order.weight).toFixed(2) },
        getRefProvider(){ return this.order.refProvider || '' },
        selectAdr(ad){
            this.$emit('selectAdr', ad)
            this.showModal = false
        }
    }

}
</script>

<style lang="scss" scoped>
.resume-box{
    background: white;
    color: $dark-gray;
    box-shadow: 1px 2px 2px 0 $shadow;
    border-radius: 2px;
    width: 800px;
    min-width: 400px;
    h5{ text-align: left; }
    div{
      line-height: 2rem;
        font-size: .9rem;
        border-bottom: 1px solid $light-gray;
     &.col-4{ text-align: left; color:$green; }
     &.col-8{ text-align: right; font-weight: 600;}
    }
}

.fa-question-circle{ color:$blue; }

.fa-map-marker-alt{
  color:white;
  background:$green;
  padding: 4px 6px;
  font-size: .9rem;
  border-radius: 2px;
  cursor:pointer;
  &:hover{
    filter: brightness(90%);
  }
}

.adr-label{
    font-size: 1rem;
    cursor: pointer;
    &.selected{
        color:$green;
    }
    &:hover{
        color:$blue;
    }
}
</style>