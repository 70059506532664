<template>
    <div class="row justify-content-center mt-4">
        
        <div class="col-12 order-box my-1 p-2" v-for="o in orders" :key="o._id">
            <div class="row m-0 p-0">
                <div class="col-lg-2 col-12">
                    <label class="order" >{{o.storeCode}}-{{o.orderNumber}}</label>
                </div>
                <div class="col-lg-2 col-12">
                    <label class="status">{{$t(`status.${o.status}`)}}</label>
                </div>
                <div class="col-lg-2 col-12">
                    <label class="date"><i class="fas fa-box-open me-1"></i>{{parseDate(o.creationDate)}}</label>
                </div>
                <div class="col-lg-2 col-12">
                    <label class="date"><i class="fas fa-box-open me-1" v-if="o.deliveryPromiseDateToCarrier">
                        </i>{{o.deliveryPromiseDateToCarrier?parseDate(o.deliveryPromiseDateToCarrier):'-'}}</label>
                </div>
                 <div class="col-lg-2 col-12">
                    <label class="status"><i class="fas fa-truck me-1" v-if="getCarrier(o)!='-'"></i>{{getCarrier(o)}}</label>
                </div>
                <div class="col-lg-2 col-12 options">
                    <span class="fas fa-edit" :class="{'disabled': !canPrepare(o) || (!isAdmin() && !isProvider()) }" :title="$t('preparation.prepare')" 
                        @click="(canPrepare(o) && (isAdmin() || isProvider()))?$emit('orderSelect',o):''"></span>
                    <span class="fas fa-print" :class="{'disabled': !canReprint(o) }" :title="$t('preparation.reprint')" 
                        @click="canReprint(o)?$emit('print',o):''"></span>
                    <input v-if="notifyMany" type="checkbox" class="form-check-input ms-2" v-model="o.checked" @click="$emit('check',{id:o._id, checked:o.checked})" 
                        :disabled="!canNotify(o)">
                    <span v-else class="far fa-bell" :class="{'disabled': !canNotify(o) || (!isAdmin() && !isProvider()) }" :title="$t('preparation.notify')" 
                        @click="canNotify(o)?$emit('notify',o):''"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name:'order-list',
    props:['orders','notifyMany'],
    data:() =>{
        return {
            
        }
    },
    methods: {
        parseDate(d){ return `${('0'+new Date(d).getDate()).slice(-2)}/${('0'+ (new Date(d).getMonth()+1)).slice(-2)}/${new Date(d).getFullYear()}`},
        getCarrier(order){ return order.transport?.carrier?.name ?? '-' },
        canPrepare(order){ return true/*['init','in_progress','prepared'].includes(order.status)*/ },
        canReprint(order){ return order.transport && order.status=='labeled'},
        canNotify(order){ return order.transport && order.status=='labeled' },
    }
}
</script>
<style lang="scss" scoped>
.order-box{
    background: white;
    color: $dark-gray;
    box-shadow: 1px 2px 2px 0 $shadow;
    .order{
        font-weight: 600;
        font-size: 1.2rem;
    }
    .date{ color:$green;}
    .avail-date{ 
        color:$blue;
        &.late{
            color:orange;
            font-weight: 550;
        }
    }
    div{
        line-height: 1.6rem;
    }
    .options{
        text-align: right;
        span{
            margin-left: .4rem;
            
            color:white;
            border-radius: 4px;
            width: 1.6rem;
            height: 1.6rem;
            font-size: 1rem;
            padding-top: .3rem;
            text-align: center;
            cursor: pointer;
            &:first-of-type{
                background: $blue;
            }
            &:nth-of-type(2){
                background: $green;
            }
            &:nth-of-type(3){
                background: sandybrown;
            }
            &:hover{
                filter: brightness(90%);
            }
            &.disabled{
                filter: grayscale(50%);
                opacity: .3;
                cursor: not-allowed;
            }
            
        }
    }
}
</style>