<template>
    <div class="container-fluid m-0 p-0 min-height">
        <div class="row justify-content-center mt-2">
            <div class="col-auto">
                <label class="form-label">{{$t('order.order')}}:</label>
                <div class="input-group"><span style="line-height:38px;padding-right:4px">XX-</span><input v-model="orderNumber" class="form-control iniput"></div>
            </div>
            <div class="col-auto">
                <label class="form-label">{{$t('order.creationDate')}}:</label>
                <date-picker class="iniput" v-model="filters.creationDate" range/>
            </div>
            <div class="col-auto">
                <label class="form-label">{{$t('order.deliveryPromiseDateToCarrier')}}:</label>
                <date-picker class="iniput" v-model="filters.deliveryPromiseDateToCarrier" range/>
            </div>
            <div class="col-auto">
                <label class="form-label">{{$t('order.status')}}:</label>
                <select v-model="filters.status" class="form-control iniput">
                    <option :value="null">{{$t('all')}} </option>
                    <option v-for="(s,i) in statuses" :key="`st${i}`" :value="s">{{$t(`status.${s}`)}}</option>
                </select>
            </div>
           
            <div class="col-auto">
                <label class="form-label">{{$t('order.carrierName')}}:</label>
                <model-list-select :list="getCarriers()" option-value="id" class="form-control" style="min-width:150px" 
                    :custom-text="codeAndNameCarriers" v-model="filters.carrier" :placeholder="$t('all')"/>
            </div>
        </div>
        <div class="row justify-content-center mt-4">
            <div class="col-9">
                <div class="row">
                    <div v-for="(h,i) in headers" :class="`col-${h.size} cell table-header ${h.sortable?'sortable':''}`" :key="`header${i}`" @click="h.sortable?sortTable(h.val):''">
                        {{getText(h.text)}} <i v-if="h.sortable && sort.key==h.val" :class="`fas fa-sort-amount-${sort.order=='ASC'?'up':'down'}`"></i>
                    </div>
                    <div class="col-1 cell table-header" style="text-align:end;padding-right:20px!important">{{$t('options')}}</div>
                </div>
                <div class="row" v-if="loading">
                    <loader :msg="'getting_orders'"/>
                </div>
                <div class="row" v-else-if="total==0">
                    <div class="col-12 none">{{$t('no_results')}}</div>
                </div>
                <div class="row" v-else>
                    <div class="col-12">
                        <div class="row mt-2 cell" v-for="(o,i) in list" :key="`order${i}`">
                            <div :class="`col-lg-${h.size} col-12`" v-for="(h,j) in headers" :key="`cell${i}.${j}`">
                                {{getValue(h.val,o)}}
                            </div>
                            <div class="col-lg-1 col-12 options">
                                <span v-if="canReprint(o)"  class="fas fa-print"  :title="$t('preparation.reprint')" 
                                @click="printTickets(o)"></span>
                            </div>                            
                        </div>
                        <div class="row mt-2">
                            <div class="col-12 cell table-header" style="text-align:end;font-weight:light;"> 
                                <button class="pager" :disabled="filters.page==0" @click="filters.page=0"><i class="fas fa-angle-double-left"></i></button>
                                <button class="pager" :disabled="filters.page==0" @click="filters.page--"><i class="fas fa-angle-left"></i></button>
                                <template v-for="i in [filters.page-2,filters.page-1, filters.page, filters.page+1, filters.page+2]">
                                    <button :class="`pager${filters.page==i?' selected':''}`" :key="`page${i}`" v-if="i>-1 && i<getTotalPages()"
                                        style="font-size:12px;padding:3px; line-height:16px" @click="filters.page=i">{{i+1}}</button>
                                </template>
                                <button class="pager" :disabled="filters.page+1==getTotalPages()" @click="filters.page++"><i class="fas fa-angle-right"></i></button>
                                <button class="pager" :disabled="filters.page+1==getTotalPages()" @click="filters.page=getTotalPages()-1"><i class="fas fa-angle-double-right"></i></button>
                                Total: <b>{{total}}</b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>
<script>
import DatePicker from 'vue2-datepicker';
import Loader from '@/components/Loader'
import { ModelListSelect  } from 'vue-search-select'
import { STATUSES } from '@/services/utils'
import {getOrderHistory} from '@/services/orders'
import {getProviderList} from '@/services/providers'
import {format, subDays} from 'date-fns'
import {printOnWindow} from '@/services/utils'
import * as orderService from '@/services/orders'

export default {
    components: { DatePicker, ModelListSelect , 'loader': Loader },
    data: () => {
        return {
            loading: true,
            providers: [],
            carriers: [],
            filters:{
                page:0,
                provider: 'all',
                carrier: 'all',
                creationDate: [subDays(new Date(),7), new Date()],
                deliveryPromiseDateToCarrier: null,
                status: null
            },
            orderNumber:'',
            sort:{ key: null, order: 'DESC'},
            headers:[
                { val:'supplierCode', text: 'provider', sortable: true, size:'2' },
                { val:'orderNumber', text: 'order', sortable: true, size:'1' },
                { val:'status', text: 'status', sortable: true, size:'1'},
                { val:'creationDate', text: 'creationDate', sortable: true, size:'2'},
                { val:'deliveryPromiseDateToCarrier', text: 'deliveryPromiseDateToCarrier', sortable: true, size:'2'},
                { val:'carrierName', text: 'carrierName', sortable: true, size:'1'},
                { val:'bulks', text: 'bulks', size:'1'},
                { val:'weight', text: 'total_weight', size:'1'}
            ],
            statuses: STATUSES,
            total: 0,
            list: []
        }
    },
    watch:{
        filters: {
            handler: function(newVal){ 
                this.loadCarriers() 
                this.loadOrders() 
            },
            deep: true
        },
        providerImpersonate: function(newVal){ this.filters.provider=newVal??'all' },
        orderNumber: function(newVal){ if(!newVal || newVal.length>=3) this.loadOrders()}
    },
    mounted(){
        this.loadProviders()
        this.loadCarriers()
        this.loadOrders()
        this.filters.provider=this.providerImpersonate
    },
    methods:{
        async loadProviders(){
            try{
                let {data} = await getProviderList()
                this.providers = [{leroy_id:'all', name: this.$t('all')}].concat(data)
            }catch(err){ console.log(err)}
        },
        async loadCarriers(){
            try{
                let {data} = await orderService.getCarriers(this.providerImpersonate)
                this.carriers = data
            }catch(err){ console.log(err)}
        },
        getCarriers(){
            return [...[{id:'all', name: this.$t('all')}, ...this.carriers]]
        },
        async loadOrders(){
            this.loading= true
            try{
                this.filters.provider=this.providerImpersonate
                let query = JSON.parse(JSON.stringify(this.filters))
                Object.keys(query).forEach(k=> { 
                    if(!query[k] || ((k=='provider' || k=='carrier') && query[k]=='all')) delete query[k] 
                    else if(k.includes('deliveryPromiseDateToCarrier')){
                        if(query[k][0]) query.dateStartDeliveryPromiseDateToCarrier = format(new Date(query[k][0]), "yyyy-MM-dd'T'HH:mm:ss.SSS")
                        if(query[k][1]) query.dateEndDeliveryPromiseDateToCarrier = format(new Date(query[k][1]), "yyyy-MM-dd'T'HH:mm:ss.SSS")
                        delete query[k]
                    } else if(k.includes('Date')){
                        if(query[k][0]) query.dateStart = format(new Date(query[k][0]), "yyyy-MM-dd'T'HH:mm:ss.SSS")
                        if(query[k][1]) query.dateEnd = format(new Date(query[k][1]), "yyyy-MM-dd'T'HH:mm:ss.SSS")
                        delete query[k]
                    }
                })
                if(this.orderNumber && this.orderNumber.length>=3) query.orderNumber = this.orderNumber
                if(this.sort.key) query.sort = `${this.sort.key}.${this.sort.order}`
                let {data} = await getOrderHistory(query)
                this.total = data.total
                this.list = data.list
            }catch(err){
                this.$swal('ERROR', this.$t('error_getting_orders'), 'error')

            }
            this.loading=false
        },
        getValue(key, order){
            if(key=='orderNumber') return `${order.storeCode}-${order.orderNumber}`
            else if(key=='status') return this.$t(`status.${order.status}`)
            else if(key=='carrierName') return order.transport?.carrier?.name ?? '-'
            else if(key.includes('Date')) return order[key]? format(new Date(order[key]), 'dd/MM/yyyy'): '-'
            else if(key=='bulks') return order.transport?.bulkWeights?.length ?? '-'
            else if(key=='weight') return order.transport?.bulkWeights?.reduce((res,w)=> res+=Number(w),0)?.toFixed(2) ?? '-'
            else if(key=='supplierCode') return order[key]? `${order[key]} | ${this.providers.find(p=> p.leroy_id==order[key])?.name ?? ''}` : '-'
            else return order[key]
        },
        getText(text){ return (['order','bulks'].includes(text)? 'Nº ':'') +this.$t(`order.${text}`)},
        getTotalPages(){
            return Math.trunc(this.total/50) + (this.total%50>0? 1:0)
        },
        sortTable(key){
            this.sort.order = this.sort.key == key && this.sort.order == 'ASC'? 'DESC':'ASC'
            this.sort.key = key
            this.loadOrders()
        },
        canReprint(order){ return order.transport && ['labeled','notified'].includes(order.status)},
        async printTickets(order){
            this.loadingMsg ='preparation.get_ticket'
            this.loading = true
            try{
                let { data } = await orderService.reprint(order)
                printOnWindow(data.pdf, order)
            }catch(err){
                this.$swal('Error', this.$t('preparation.error_get_ticket'), 'error')
            }
            this.loading=false
            
        },
    }
}
</script>
<style lang="scss" scoped>

.form-control{
    height: 34px;
    line-height: 20px;
    color:#888;
}
.form-label{
    color:$green;
    margin-bottom:0;
}

.iniput{
    min-width:200px;
    font-size: .8rem;
}

.cell{
    padding: 8px!important;
    text-align: center;
    font-size: .9rem;
    line-height: .9rem;
    background: white;
    color: $dark-gray;
    box-shadow: 1px 2px 2px 0 $shadow;
    font-family: 'LM-regular', 'Helvetica', 'Arial';
}
.table-header{
    background: rgb(197, 253, 197);
    font-family: 'LM-Semibold';
    font-size: 1rem;
    line-height: 1rem;
    &.sortable:hover{
        filter:brightness(95%);
        cursor: pointer;
    }

    .pager{
        background: $green;
        border:none;
        width: 20px;
        height: 20px;
        border-radius: 2px;
        color:white;
        font-size: 16px;
        padding: 2px;
        margin-right: .25rem;
        margin-left: .25rem;
        cursor: pointer;
        &:hover{
            &:not(:disabled) {filter: brightness(95%);}
        }
        &:disabled{ filter: grayscale(100%); }
        &.selected{ background:$blue; }
    }
}
.none{
    font-size: 1.2rem;
    text-align: center;
    margin-top: 1rem;
    background: white;
    color:$blue;
    padding: .5rem;
}
.options{
        text-align: center;
        span{
            margin-left: .4rem;
            
            color:white;
            border-radius: 4px;
            width: 1.6rem;
            height: 1.6rem;
            font-size: 1rem;
            padding-top: .3rem;
            text-align: center;
            cursor: pointer;
            &:first-of-type{
                background: $blue;
            }
            &:nth-of-type(2){
                background: $green;
            }
            &:nth-of-type(3){
                background: sandybrown;
            }
            &:hover{
                filter: brightness(90%);
            }
            &.disabled{
                filter: grayscale(50%);
                opacity: .3;
                cursor: not-allowed;
            }
            
        }
    }
.min-height{
    min-height: 300px;
}
</style>