
export const getAppVersion = () => process.env.VUE_APP_VERSION
export const getEnvironment = () => {
    return String(process.env.VUE_APP_NODE_ENV)!='production'? ` ${process.env.VUE_APP_VERSION} (${process.env.VUE_APP_NODE_ENV})`:''
}

export const STATUSES = [
    'init',
    'in_progress',
    'labeled',
    'notified',
    'picked_up',
    'canceled'
]

export const ROL_ADMIN = Number(0)
export const ROL_PROVIDER = Number(1)

export const printOnWindow = (pdf, {storeCode, orderNumber}) => {
    let byteA = Uint8Array.from(atob(pdf).split('').map(char => char.charCodeAt(0)))
    let blob = new Blob([byteA], {type: 'application/pdf'})
    let mywindow = window.open(URL.createObjectURL(blob),'_blank')//, 'height=1080,width=1920')
    if (mywindow) {
        mywindow.document.title = `TICKET_${storeCode}_${orderNumber}.pdf`
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus()
    }
}

export const COUNTRIES = [
    'ES',
    'PT'
]

export const COUNTRY_CODES = [
    {num: 11, code:'ES'},    
    {num: 10, code:'PT'}    
]
