<template>
    <div class="col-12 loader justify-content-center">
          <section class="spinner-wrapper" id="spinnerWrapper">
              <div class="spinner" data-id="spinner">
                  <div class="spinner-cell">
                      <div class="pl pl-leapfrog"></div>
                  </div>
              </div>
          </section>
          <h4 v-if="msg">{{$t(msg)}}</h4>
      </div>
</template>
<script>
export default {
    name:'loader',
    props:['msg']
}
</script>
<style lang="scss" scoped>
h4{
    width: 100%!important;
    text-align: center!important;
}
</style>