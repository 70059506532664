import { URLSearchParams } from "core-js/modules/web.url-search-params";
import { api } from "./http";

const path = '/api/orders'
export const getOrders = async providerId => api.get(`${path}/${providerId}`)

export const getActiveOrders = async (query) => api.get(`${path}/active?${new URLSearchParams(query).toString()}`)

export const modifyOrder = order => api.put(`${path}/${order._id}`, order)

export const reprint = async order => api.patch(`${path}/reprint`, order)

export const notifyOrders = async orderIds => api.patch(`${path}/notify`, orderIds)

export const getOrderHistory = async query => api.get(`${path}/search?${new URLSearchParams(query).toString()}`)

export const getManifest = async order => api.patch(`${path}/manifest`, order)

export const getCarriers = async providerId => api.patch(`${path}/carriers`, (providerId && (providerId!='all'))?Array.of(providerId):null)
