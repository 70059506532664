<template>
<transition name="expand">
    <div class="popup-modal" v-if="show">
    <section class="popup-content">
        <h4 class="title mb-3">{{title}}</h4>
        <div class="row m-2 px-1">
            <section v-if="loading">
                <div class="col-12 loader">
                        <section class="spinner-wrapper" id="spinnerWrapper">
                            <div class="spinner" data-id="spinner">
                                <div class="spinner-cell">
                                    <div class="pl pl-leapfrog"></div>
                                </div>
                            </div>
                        </section>
                    </div>
            </section>
            <slot v-else name="body"/>
        </div>
        <div class="text-center mt-3" v-if="!loading">
            <button class="button negative" @click="$emit('close')">{{$t('cancel')}}</button>
            <slot name="footer"/>
        </div>
    </section>
    </div>
</transition>
</template>
<script>
export default {
    props:['show', 'title', 'loading'],
    name:'modal'
}
</script>
<style lang="scss" scoped>

.expand-enter-active { transition: all 0.5s ease-in-out; }
.expand-leave-active { transition: all 0.4s ease-in-out; }
.expand-enter, .expand-leave-to { transform: scale(0); opacity: 0; }

.popup-modal {
    background-color: rgba(255, 255, 255, .9);
    width: 100%;
    position: fixed;
    z-index: 1000;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: 'LM-Light', 'Helvetica', 'Arial';

    .row-title {
        color: $dark-gray;
        font-size: 16px;
        button{
            float: right;
            background: $light-green;
            color: white;
            border:none;
            padding: 4px 8px;
        }
    }

    

    .popup-content {
        background: white;
        opacity: 1;
        border-radius: 2px;
        border: solid 1px #E8E8E8;
        box-shadow: 1px 2px 2px 0 rgba(205,205,205,0.5);
        max-width: 400px;
        margin: 10% auto;
        padding: 1rem;
        .title{ color:black; font-size: 18px; border-bottom: .5px solid $green; text-align: center; font-weight: 600; padding-bottom: 4px;}

        .button {
            margin-top: 1rem;
            padding: .5rem 1rem;
            &:disabled{ opacity: .6; cursor: default; filter: grayscale(80%);}
        }
        .form-group{
            label{ color:$green!important;}
        }

        .checker{
            display:flex; 
            flex-direction:column;
            text-align:center;
            label{
                color:#333;
                font-weight:600;
            }
            input{
                margin:0 auto;
            }
        }
    }
    
}



</style>