<template>
    <div class="container-fluid m-0 p-0">
        <div class="row" v-if="loading">
            <loader :msg="'address.loading'"/>
        </div>
        <template v-else-if="!form.show">
            <div class="row justify-content-end mb-2 p-0">
                <div class="col-auto me-2"><button class="button mt-0" @click="$emit('back')">{{$t('back')}}</button></div>
                <div v-if="isAdmin() || isProvider()" class="col-auto"><button class="button negative mt-0" @click="showForm()">{{$t('address.add')}}</button>
                </div>
            </div>
            <div class="row m-0 p-0">
                <div class="col-xl-3 col-lg-4 col-md-6 col-12 m-0 p-3" v-for="(a,i) in provider.addresses" :key="`adr${i}`">
                    <div class="row adr-box p-2">
                        <div class="col-12">
                            <i :class="`${a.is_default?'fas':'far'} fa-star`" :title="$t('address.favourite-mark')" @click="setDefault(a)"></i> {{getAddress(a)}} 
                        </div>
                        <div v-if="isAdmin() || isProvider()" class="col-12 mt-2">
                            <span class="fas fa-edit" :title="$t('edit')" @click="showForm(a)"></span>
                            <span class="fas fa-trash-alt ms-2" :title="$t('delete')" @click="remove(a)" :class="{'disabled': provider.addresses.length==1 }"></span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        
        <template v-else>
            <div class="row justify-content-end">
                <div class="col-auto"><button class="button  mt-0" @click="form.show = false">{{$t('back')}}</button></div>
                <div class="col-auto"><button class="button negative  mt-0" @click="save()" :disabled="!isSaveable()">{{$t('save')}}</button></div>
            </div>
            <div class="row">
                <div class="form-group col-xl-4 col-md-6 col-12">
                    <span style="color: red">*&nbsp;</span>
                    <label>{{$t('address.adr')}} 1</label>        
                    <input type="text" class="form-control" v-model="form.adr.address1" @change="deleteDiacriticsEs(form.adr.address1,'address1')">
                </div>
                 <div class="form-group col-xl-4 col-md-6 col-12">
                    <label>{{$t('address.adr')}} 2</label>
                    <input type="text" class="form-control" v-model="form.adr.address2" @change="deleteDiacriticsEs(form.adr.address2,'address2')">
                </div>
            </div>
            <div class="row">
                <div class="form-group col-xl-2 col-md-6 col-12">
                    <span style="color: red">*&nbsp;</span>
                    <label>{{$t('address.city')}}</label>
                    <input type="text" class="form-control" v-model="form.adr.city" @change="deleteDiacriticsEs(form.adr.city,'city')">
                </div>
                <div class="form-group col-xl-2 col-md-6 col-12">
                    <label>{{$t('address.district')}}</label>
                    <input type="text" class="form-control" v-model="form.adr.district" @change="deleteDiacriticsEs(form.adr.district,'district')">
                </div>
                <div class="form-group col-xl-2 col-md-4 col-12">
                    <span style="color: red">*&nbsp;</span>
                    <label>{{$t('address.province')}}</label>
                    <input type="text" class="form-control" v-model="form.adr.province" @change="deleteDiacriticsEs(form.adr.province,'province')">
                </div>
                <div class="form-group col-xl-2 col-md-4 col-12">
                    <span style="color: red">*&nbsp;</span>
                    <label>{{$t('address.zip')}}:</label>
                    <input type="text" class="form-control" v-model="form.adr.zipcode">
                </div>
                <div class="form-group col-xl-2 col-md-4 col-12">
                    <span style="color: red">*&nbsp;</span>
                    <label>{{$t('address.country')}}:</label>
                    <select v-model="form.adr.country_id" class="form-control iniput" @change="updateCountryName()" >             
                        <option v-for="(s,i) in countries" :key="`co${i}`" :value="s.num">{{$t(`country.${s.code}`)}}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-12">
                    <div class="row form-group">
                        <label class="col-12"><i class="fas fa-map-marked-alt"></i> {{$t('address.coordinates')}}</label>
                        <div class="col-5"><input class="form-control" type="number" min="-90" max="90" step="0.0001" @change="fixLatitude()" v-model="form.adr.coordinates.latitude"></div>
                        <div class="col-5 ms-1"><input class="form-control" type="number" min="-180" max="80" step="0.0001" @change="fixLongitude()" v-model="form.adr.coordinates.longitude"></div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
import {getProvider, updateProvider, validateProviderAddress} from '@/services/providers'
import Loader from '@/components/Loader'
import { COUNTRY_CODES } from '@/services/utils'
export default {
    name:'address-managment',
    components: {Loader},
    props:['provider'],
    data:() =>{
        return {
            loading: false,
            selectedAddress: null,
            form:{
                show: false,
                isNew: false,
                adr: null
            },
            countries: COUNTRY_CODES           
        }
    },
    watch:{
        'provider': function(newVal){ this.loading=false}
    },
    methods:{
        loadProvider(){
            
            this.$emit('loadProvider')
            this.loading = false
            this.closeForm()
           
        },
        getAddress(adr){ 
            let res = `${adr.address1}${adr.address2?' '+adr.address2: ''}, ${adr.zipcode} ${adr.province}` 
            return res.length>30? res.substring(0,30)+'...' : res
        },
        showForm(adr){
            this.form.isNew = adr? false : true
            this.selectedAddress = adr
            this.form.adr = adr ? JSON.parse(JSON.stringify(adr)) : {
                is_default:false,
                address1: "", 
                address2: "",
                city:"",
                district:"",
                province:"",
                zipcode:"",
                country_id:"11",
                country_name:"ES",
                coordinates: {latitude: null, longitude: null}}

            this.form.show = true
        },
        closeForm(){
            this.form.show = false
        },
        async setDefault(adr){
            if(!adr.is_default){
                let p = JSON.parse(JSON.stringify(this.provider))
                 p.addresses = p.addresses.map(a=> {
                    a.is_default= JSON.stringify(a)==JSON.stringify(adr)
                    return a
                })
                try{
                    await updateProvider(p)
                    this.showToast(this.$t('address.favorite-saved'), 'success')
                    this.loadProvider()
                }catch(err){
                    console.log(err)
                    this.showToast(this.$t('address.save-error'), 'error')
                }
            }
            
        },
        isSaveable(){ return JSON.stringify(this.selectedAddress)!==JSON.stringify(this.form.adr)},
        fixLatitude(){
            if(this.form.adr.coordinates.latitude>90) this.form.adr.coordinates.latitude=90
            else if(this.form.adr.coordinates.latitude<-90) this.form.adr.coordinates.latitude=-90
        },
        fixLongitude(){
            if(this.form.adr.coordinates.longitude>80) this.form.adr.coordinates.longitude=80
            else if(this.form.adr.coordinates.longitude<-180) this.form.adr.coordinates.longitude=-180
        },
        updateCountryName(){
            this.form.adr.country_name =  COUNTRY_CODES.find(cc=> cc.num == Number(this.form.adr.country_id))?.code ?? 'ES'        
        },        
        async save(){

            try{                
                await validateProviderAddress(this.form.adr)   
                this.loading=true
                if(this.selectedAddress)
                    Object.keys(this.selectedAddress).forEach(k=> this.selectedAddress[k]=this.form.adr[k])
                else this.provider.addresses.push(this.form.adr)
                try{
                    await updateProvider(this.provider)
                    this.showToast(this.$t('address.saved'), 'success')
                    this.loadProvider()
                }catch(err){
                    console.log(err)
                    this.showToast(this.$t('address.save-error'), 'error')
                    this.loading = false
                }
            }catch(err){   
                console.log(err)                                   
                let res = JSON.parse( JSON.stringify(err.response.data.errors));             
                let errorList='<h5 style="text-align:left">';
                for (let i = 0; i < res.length; i++) {
                     errorList +=  this.$t(`validation_address.${res[i].code}`)+ "<br>"; 
                }   
                errorList += '</h5>'
                this.$swal({
                    icon: 'error',
                    title: this.$t('validation_address.title'),                      
                    html: errorList,                                
                    type: 'error'                         
                })
               
            }        
        },
        async remove(adr){
            if(this.provider.addresses.length>1){
                let {value} = await this.$swal({ title: this.$t('confirm'), type: 'question', text: this.$t('address.cancel-question'),
                    cancelButtonText: this.$t('cancel'), confirmButtonText: this.$t('accept'), showCancelButton: true, reverseButtons:true })
                if(value){
                    this.loading= true
                    try{
                        let p = JSON.parse(JSON.stringify(this.provider))
                        p.addresses = p.addresses.filter(a=> JSON.stringify(a)!==JSON.stringify(adr))
                        if(!p.addresses.find(a=> a.is_default)) p.addresses[0].is_default=true
                        await updateProvider(p)
                        this.showToast(this.$t('address.deleted'), 'success')
                        this.loadProvider()
                    }catch(err){
                        console.log(err)
                        this.showToast(this.$t('address.save-error'), 'error')
                        this.loading = false
                    }
                }
            }
        },
        // Removes diacritics from a text except "ñ" (ES6)
        deleteDiacriticsEs(elem, id) {
            let resultado = elem
           .normalize('NFD')
           .replace(/([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi,"$1")
           .normalize();
            eval(`this.form.adr.${id}='${resultado}'`);             
        }
    }
}
</script>
<style lang="scss" scoped>
.adr-box{
    background: white;
    box-shadow: 1px 2px 2px 0 $shadow;
    font-family: 'LM-Regular', 'Helvetica', 'Arial';
    font-size: 1.1rem;
    .fa-star{
        color: rgb(218, 159, 31);
        
        &:hover{ 
            &:not(.fas){ color:yellowgreen;cursor:pointer;}
        }
    }
    span{
        background: $green;
        color:white;
        border-radius: 4px;
        width: 1.6rem;
        height: 1.6rem;
        font-size: 1rem;
        padding-top: .3rem;
        text-align: center;
        cursor: pointer;
        &:first-of-type{
            background: $blue;
        }
        &:last-of-type{
            background: rgb(179, 40, 40);
        }
        &:hover{
            filter: brightness(90%);
        }
        &.disabled{
            filter: grayscale(50%);
            opacity: .3;
            cursor: not-allowed;
        }
        
    }
}

.form-group{
    margin-bottom: 1rem;
    label{
        color:$green;
        font-size: .9rem;
        margin-bottom: .5rem;
    }
    .form-control{
        box-shadow: 1px 2px 2px 0 $shadow;
    }
}

</style>